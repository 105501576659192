html, body{
  width: 100%;
  min-height: 100%;
  position: relative;
  display: block;
  margin: 0;
  font-family: 'Ubuntu', sans-serif;
}

body{
    padding-bottom: 100px;
    padding-top: 56px;
}
