section.steps-container{
    padding: 50px 0;
    width: 100%;
    text-align: center;
    color: #160C28;
}

section.steps-container .steps{
    display: flex;
    align-items: center;
    width: 100%;
    margin: auto;
    flex-wrap: wrap;
}

section.steps-container .steps .step{
    margin: 15px auto;
    width: 33.33%;
    text-align: center;
}

section.steps-container .steps .step img{
    width: 250px;
    display: block;
    margin: 15px auto;
}

section.steps-container .steps .step .step-description{
    margin: 25px 0;
    max-width: 250px;
    font-size: 18px;
    color: #160C28;
}

.totaun_video{
    width: 560px;
    height: 315px;
}

@media all and (max-width: 1200px){
    section.steps-container .steps .step{
        width: 50%;
    }
}

@media all and (max-width: 767px){
    section.steps-container .steps .step{
        width: 100%;
    }
    .totaun_video{
        width: 90%;
    }
}